



































































































































































































































































// import DatePickerConvert from "@/components/_universal/date-picker-convert.vue";
import editMixin from "@/mixin/edit-mixin";
import MODULE from "@/constants/modules";
import GlobalServices from "@/services/global";
import ProjectService from "@/services/projects";
// import ApplicationService from "@/services/application";
// import BaseController from "@/controllers/base-controller";
// const app = new ApplicationService();
const global = new GlobalServices();
const projectApi = new ProjectService();
export default {
  mixins: [editMixin],
  components: {
    DatePickerConvert: () => import("@/components/_universal/date-picker-convert.vue"),
  },
  computed: {
    hasRelatedJob() {
      return this.dataObj.details.IntProjectID;
    },
  },
  data() {
    return {
      supplierDetails: {},
      projectDetails: {},
    };
  },
  methods: {
    // navigateToOldSite() {
    //   const baseController = new BaseController();
    //   const action = baseController.getOldPurchaseOrderAction(this.dataObj.details, this.dataObj.details.actionId);
    //   app.actionNavigation(action);
    // },
    async initializedInfo() {
      if (this.dataObj) {
        if (this.dataObj.details && this.dataObj.details.IntSupplierID) {
          this.supplierDetails = await global.getEditDetails(MODULE.SUPPLIER.ActionId, this.dataObj.details.IntSupplierID);
        }
        if (this.dataObj.details && this.dataObj.details.IntProjectID) {
          this.projectDetails = await projectApi.getProjectDetails(this.dataObj.details.IntProjectID);
        }
      }
    },
  },
  mounted() {
    this.initializedInfo();
  },
  created() {
    this.MODULE = MODULE;
  },
};
